import { IDomain } from "@bprotsyk/aso-core";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CheckPicker,
  Input,
  InputGroup,
  Message,
  Progress,
  Stack,
  Table,
  toaster,
  Uploader,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import ContainerTable from "./table/table";
import "./_domains.scss";
import AddDomainModal from "../../../ui/modals/domains/add-domains";
import EditingRecordsModal from "../../modals/domains/host-settings";
import BuyDomainModal from "../../../ui/modals/domains/buy-domain";
import React from "react";
import StaticModal from "../../../ui/modals/domains/add-static-files";
import { title } from "process";
import CustomFilterPicker from "./custom-filter-picker";
import ArchivedDomainsModal from "ui/modals/domains/archive-modal";
import { FileType } from "rsuite/esm/Uploader";
import { PanelService } from "network/panel/network";

const PREF_NAMECHEAP_BALANCE = "namecheap-balance";
const PREF_FILTER_SHOW_ARCHIVED = "filter-selector";
const DEPLOY_STATUS = "delpoy_status";
const placement = "topEnd";
let previousValue = sessionStorage.getItem(DEPLOY_STATUS);

interface ISearchInput {
  search: string;
  setSearch: (value: string) => void;
}
const nameCheapInit = () => {
  return parseFloat(localStorage.getItem(PREF_NAMECHEAP_BALANCE) || "0");
};
interface FilterState {
  status: string[];
  archived: string[];
  target: string[];
}

interface IStaticDeploy {
  file: File;
  name: string;
}

// search input
const CustomInputGroup = ({ search, setSearch }: ISearchInput) => (
  <InputGroup className="domain-search" style={{ width: 340 }}>
    <Input
      placeholder="Пошук..."
      value={search}
      onChange={(value: string) => setSearch(value)}
    />
    <InputGroup.Addon>
      <SearchIcon />
    </InputGroup.Addon>
  </InputGroup>
);

export default function DomainsPage() {
  let [namecheapBalance, setNamecheapBalance] = useState<number>(
    nameCheapInit()
  );
  const [refreshTableData, setRefreshTableData] = useState(false);

  const [toglleModallAddDomain, setTogllerModallAddDomain] =
    useState<boolean>(false);

  const [toglleModallBuyDomain, setToglleModallBuyDomain] =
    useState<boolean>(false);

  const [toglleModallEditingDomain, setToglleModallEditingDomain] =
    useState<boolean>(false);

  const [toglleModallEditingStatic, setToglleModallEditingStatic] =
    useState<boolean>(false);

  const [toglleModallArchive, setToglleModallArchive] =
    useState<boolean>(false);

  const [editingDomain, setEditingDomain] = useState<IDomain | any>(null);
  const [toggleButtonShow, setToggleButtonShow] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<FilterState>(() => {
    const localFilter = localStorage.getItem(PREF_FILTER_SHOW_ARCHIVED);
    if (localFilter) return JSON.parse(localFilter);
    return { status: [], archived: [], target: [] };
  });

  const [archivedDomains, setArchivedDomains] = useState([]);
  const [load, setLoad] = useState(false);
  // TODO ne peovtikay zberigari balans i
  // zaprashuvati kojnogo razu pisla pokupki i prosto onEffect([])

  useEffect(() => {
    PanelService.getDomainBalance().then((res) => {
      setNamecheapBalance(res);
      const resToString = String(res);
      localStorage.setItem(PREF_NAMECHEAP_BALANCE, resToString);
    });
  }, []);

  const autoDataUpdate = () => {
    setRefreshTableData(!refreshTableData);
  };

  const handleAddDomains = () => {
    setTogllerModallAddDomain(!toglleModallAddDomain);
  };

  const handleBuyDomains = () => {
    setToglleModallBuyDomain(!toglleModallBuyDomain);
  };

  const editingModal = () => {
    setToglleModallEditingDomain(!toglleModallEditingDomain);
  };

  const handleEditingDomain = (e: IDomain) => {
    editingModal();
    console.log(e);
    const updatedDomain = {
      ...e,
      txtRecords: e.txtRecords.length > 0 ? e.txtRecords : [],
    };

    setEditingDomain(updatedDomain);
  };

  const setEditStaticModal = () => {
    setToglleModallEditingStatic(!toglleModallEditingStatic);
  };

  const toggleButtonShowHidden = (e: boolean) => {
    setToggleButtonShow(e);
  };

  const toggleArchiveShowModal = () => {
    setToglleModallArchive(!toglleModallArchive);
  };

  const customFilters = (filterSelectors: FilterState) => {
    setFilter(filterSelectors);
    if (filterSelectors) {
      localStorage.setItem(
        PREF_FILTER_SHOW_ARCHIVED,
        JSON.stringify(filterSelectors)
      );
    }
  };

  const uploadStaticToServer = async (file: File) => {
    const name = editingDomain.name;
    setLoad(true);
    const body = {
      file,
      name: name,
    };
    console.log(body);
    await PanelService.uploadStatic(body);
    setLoad(false);
  };

  const message = (messageType: any, mesageText: string) => {
    return (
      <Message key={new Date().getTime()} showIcon type={messageType} closable>
        <strong>{mesageText}</strong>
      </Message>
    );
  };

  function checkSessionStorage() {
    const currentValue = sessionStorage.getItem(DEPLOY_STATUS);
    if (currentValue !== previousValue) {
      console.log(currentValue);
      previousValue = currentValue;
      if (currentValue === "true") {
        const messageText = "Всі файли успішно завантажені на сервер";

        toaster.push(message("success", messageText), {
          placement: placement,
          duration: 5000,
        });
      } else if (currentValue === "false") {
        const messageText =
          "Виникла помилка при спробі завантажити файли на сервер";
        toaster.push(message("error", messageText), {
          placement: placement,
          duration: 5000,
        });
        setLoad(false);
      }
    }
    sessionStorage.removeItem(DEPLOY_STATUS);
  }

  setInterval(checkSessionStorage, 500);

  return (
    <div className="domains">
      <div className="header">
        <span className="balance">
          Баланс Namecheap:{" "}
          <span className="balance-number">${namecheapBalance}</span>
        </span>
        <div className="button_tool_bar">
          {toggleButtonShow && (
            <Button
              color="blue"
              appearance="ghost"
              className="button-buy-domains"
              onClick={toggleArchiveShowModal}
            >
              Архівувати
            </Button>
          )}
          {!toggleButtonShow && (
            <>
              <CustomFilterPicker onSetFilter={customFilters} />

              <Button
                color="green"
                appearance="primary"
                className="button-buy-domains"
                onClick={handleBuyDomains}
              >
                Купити домени
              </Button>
              <Button
                color="yellow"
                appearance="ghost"
                className="button-add-domains"
                onClick={handleAddDomains}
              >
                Додати домени
              </Button>
            </>
          )}
        </div>
        {CustomInputGroup({ search, setSearch })}
      </div>

      <ContainerTable
        onHandleEditingDomain={handleEditingDomain}
        searchQuery={search}
        onToggleButtonShow={toggleButtonShowHidden}
        filterSelectors={filter}
        refreshTableData={refreshTableData}
      />

      {toglleModallEditingDomain && (
        <EditingRecordsModal
          editingDomainData={editingDomain}
          onEditingModal={editingModal}
          onStaticModal={setEditStaticModal}
          load={load}
        />
      )}

      {toglleModallAddDomain && (
        <AddDomainModal
          onClose={handleAddDomains}
          updateData={autoDataUpdate}
        />
      )}
      {toglleModallEditingStatic && (
        <StaticModal
          onSetEditStatic={setEditStaticModal}
          onUploads={uploadStaticToServer}
        />
      )}

      {toglleModallBuyDomain && (
        <BuyDomainModal
          balance={namecheapBalance}
          onClose={handleBuyDomains}
          updateData={autoDataUpdate}
        />
      )}

      {toglleModallArchive && (
        <ArchivedDomainsModal onClose={toggleArchiveShowModal} />
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ButtonToolbar, Checkbox, Dropdown, Radio, RadioGroup } from "rsuite";
import Navbar, { NavbarTab } from "../../components/navbar/Navbar";
import { GroupOffersBy, IOffersAdminResponse, TOffer } from "types/offers-temporary";
import "./_offers.scss"
import OfferSmall from "ui/components/offers/offer-small";
import ReactCountryFlag from "react-country-flag";
import { getEnumValue } from "utils/general";
import OfferCrud from "ui/modals/offer/offer-crud";
import { PanelService } from "network/panel/network";
// import Dashboard from "ui/components/";
// import FlashApps from '../pages/flash-apps/FlashApps';

interface IAppHubParams {
  tab: NavbarTab;
  component: JSX.Element
}

const RadioLabel = ({ children }: any) => <label style={{ padding: 7 }}>{children}</label>

const PREF_OFFER_PARTNERS_GROUP_BY_COUNTRIES = "pref_offer_partners_group_by_countries"
const PREF_OFFER_COUNTRIES_GROUP_BY_PARTNERS = "pref_offer_countries_group_by_partners"
const PREF_OFFER_SHOW_HIDDEN = "pref_offer_show_hidden"
const PREF_OFFER_SHOW_ACTIVE = "pref_offer_show_active"
const PREF_OFFER_GROUP_BY = "pref_offer_group_by"

// TODO move to separate components
let groupByPartnersLayout = (offers: IOffersAdminResponse, state: IOffersState, setOfferToEdit: React.Dispatch<React.SetStateAction<TOffer | null>>) => {
  if (!offers.byPartners) return null

  let byPartners = offers.byPartners
  let partners = offers.partners

  let sections: any[] = []

  Object.entries(byPartners).map((e) => {
    let key = parseInt(e[0])
    let value = e[1]

    if (!value || value.length == 0) return

    let partner = partners.find((p) => p.partnerId == key)

    let title = <span className="partners-title noselect">{partner?.name}</span>

    let content: any = []
    if (!state.groupPartnersByCountries) {
      content = <div className="offers-column">{
        value.map(offer => {
          return <OfferSmall onClick={setOfferToEdit} offer={offer} key={offer.id} showCountry={true} />
        })
      }
      </div>
    } else {
      let countries = new Map<String, TOffer[]>()
      value.forEach(offer => {
        let geoOffers: TOffer[] = []
        if (countries.has(offer.geo)) {
          geoOffers = countries.get(offer.geo) || []
        }
        geoOffers.push(offer)
        countries.set(offer.geo, geoOffers)
      })

      for (let geo of countries.keys()) {
        let offers: TOffer[] = countries.get(geo)!

        content.push(
          <div className="partners-section-geo">
            <div className="partners-country-container">
              <span className="partners-country noselect">{geo}</span>
              <ReactCountryFlag style={{
                fontSize: '2rem',
                lineHeight: '2rem',
              }} className="partners-country-sign noselect" countryCode={geo.toString()} />
            </div>

            {offers.map(offer => {
              return <OfferSmall onClick={setOfferToEdit} offer={offer} key={offer.id} showCountry={false} />
            })}
          </div>
        )
      }

      content = <div className="offers-column">{content}</div>
    }

    sections.push(
      <div className="partners-section" key={key}>
        {title}
        {content}
      </div>
    )
  })

  return (<div className="partners-layout">
    {/* Group by countries */}
    <Checkbox className="partners-countries-checkbox noselect" checked={state.groupPartnersByCountries} onChange={(_, value) => {
      state.setGroupPartnersByCountries(value)
      localStorage.setItem(PREF_OFFER_PARTNERS_GROUP_BY_COUNTRIES, value ? "true" : "false")
    }}>Групувати по країнам</Checkbox>


    {showHiddenCheckbox(state)}
    {showActiveCheckbox(state)}

    <div className="partners-section-content">{sections}</div>
  </div>)
}

// TODO move to separate components
let groupByCountriesLayout = (response: IOffersAdminResponse, state: IOffersState, setOfferToEdit: React.Dispatch<React.SetStateAction<TOffer | null>>) => {
  if (!response.byCountries) return null

  let byCountries = response.byCountries
  let partners = response.partners

  let sections: any[] = []

  Object.entries(byCountries).map((e) => {
    let key = e[0]
    let value = e[1]

    if (!value || value.length == 0) return

    // let partner = partners.find((p) => p.partnerId == key)

    let title = <span className="partners-title noselect">
      <span className="partners-country noselect">{key}</span>
      <ReactCountryFlag style={{
        fontSize: '2rem',
        lineHeight: '2rem',
      }} className="partners-country-sign noselect" countryCode={key.toString()} />
    </span>

    let content: any = []
    if (!state.groupCountriesByPartners) {
      content = <div className="offers-column">{
        value.map(offer => {
          return <OfferSmall onClick={setOfferToEdit} offer={offer} key={offer.id} showCountry={false} />
        })
      }
      </div>
    } else {
      let partners = new Map<number, TOffer[]>()
      value.forEach(offer => {
        let geoOffers: TOffer[] = []
        if (partners.has(offer.partnerId)) {
          geoOffers = partners.get(offer.partnerId) || []
        }
        geoOffers.push(offer)
        partners.set(offer.partnerId, geoOffers)
      })

      for (let partnerId of partners.keys()) {
        let offers: TOffer[] = partners.get(partnerId)!
        let partner = response.partners[partnerId]

        content.push(
          <div className="partners-section-geo">
            <div className="partners-country-container">
              <span className="partners-country noselect">{partner.name}</span>
            </div>

            {offers.map(offer => {
              return <OfferSmall onClick={setOfferToEdit} offer={offer} key={offer.id} showCountry={false} />
            })}
          </div>
        )
      }

      content = <div className="offers-column">{content}</div>
    }

    sections.push(
      <div className="partners-section" key={key}>
        {title}
        {content}
      </div>
    )
  })

  return (<div className="partners-layout">
    {/* Group by countries */}
    <Checkbox className="partners-countries-checkbox noselect" checked={state.groupCountriesByPartners} onChange={(_, value) => {
      state.setGroupCountriesByPartners(value)
      localStorage.setItem(PREF_OFFER_COUNTRIES_GROUP_BY_PARTNERS, value ? "true" : "false")
    }}>Групувати по партнеркам</Checkbox>


    {showHiddenCheckbox(state)}
    {showActiveCheckbox(state)}

    <div className="partners-section-content">{sections}</div>
  </div>)
}

let showHiddenCheckbox = (state: IOffersState) => {
  return (<Checkbox className="show-hidden-checkbox noselect" checked={state.showHidden} onChange={(_, value) => {
    state.setShowHidden(value)
    localStorage.setItem(PREF_OFFER_SHOW_HIDDEN, !value ? "true" : "false")
  }}>Показати приховані</Checkbox>)
}

let showActiveCheckbox = (state: IOffersState) => {
  return (<Checkbox className="show-active-checkbox noselect" checked={state.showActive} onChange={(_, value) => {
    state.setShowActive(value)
    localStorage.setItem(PREF_OFFER_SHOW_ACTIVE, !value ? "true" : "false")
  }}>Показати неактивні</Checkbox>)
}


interface IOffersState {
  setGroupPartnersByCountries: React.Dispatch<React.SetStateAction<boolean>>
  groupPartnersByCountries: boolean,
  setGroupCountriesByPartners: React.Dispatch<React.SetStateAction<boolean>>
  groupCountriesByPartners: boolean,
  setShowHidden: React.Dispatch<React.SetStateAction<boolean>>,
  showHidden: boolean,
  setShowActive: React.Dispatch<React.SetStateAction<boolean>>,
  showActive: boolean,
}

export default () => {
  let groupByDefault = localStorage.getItem(PREF_OFFER_GROUP_BY) || GroupOffersBy.COUNTRIES
  let [groupBy, setGroupBy] = useState<String>(groupByDefault)
  let [groupPartnersByCountries, setGroupPartnersByCountries] = useState<boolean>(localStorage.getItem(PREF_OFFER_PARTNERS_GROUP_BY_COUNTRIES) == "true")
  let [groupCountriesByPartners, setGroupCountriesByPartners] = useState<boolean>(localStorage.getItem(PREF_OFFER_COUNTRIES_GROUP_BY_PARTNERS) == "true")
  let [showHidden, setShowHidden] = useState<boolean>(localStorage.getItem(PREF_OFFER_SHOW_HIDDEN) != "true")
  let [showActive, setShowActive] = useState<boolean>(localStorage.getItem(PREF_OFFER_SHOW_ACTIVE) != "true")

  let [offers, setOffers] = useState<IOffersAdminResponse | null>()
  let [offerToEdit, setOfferToEdit] = useState<TOffer | null>(null)

  useEffect(() => {
    (async () => {
      try {
        let offers = await PanelService.getAllOffers(groupBy.toString(), showHidden, showActive) // parameters to group etc.

        console.log(offers)

        setOffers(offers)
      } catch (e) {
        alert(`Не можу отримати список оферів, помилка: ${e}`)
      }
    })()
  }, [showHidden, showActive, groupBy])

  let state: IOffersState = {
    setGroupPartnersByCountries: setGroupPartnersByCountries,
    groupPartnersByCountries: groupPartnersByCountries,
    setGroupCountriesByPartners: setGroupCountriesByPartners,
    groupCountriesByPartners: groupCountriesByPartners,
    setShowHidden: setShowHidden,
    showHidden: showHidden,
    setShowActive: setShowActive,
    showActive: showActive,
  }

  return (
    <div className="offers">
      <RadioGroup name="groupByGroup" className="groupByGroup" onChange={(v, e) => {
        let value = v.toString()

        localStorage.setItem(PREF_OFFER_GROUP_BY, value)
        setGroupBy(value)
      }} inline appearance="picker" defaultValue={groupBy as GroupOffersBy}>
        <RadioLabel>Групувати за: </RadioLabel>
        <Radio value={GroupOffersBy.COUNTRIES}>Країнами</Radio>
        <Radio value={GroupOffersBy.PARTNERS}>Партнерками</Radio>
      </RadioGroup>
      {/** Добавляем здесь радио групп, выбираем тип отображения – вместе, по партнёркам, по странам */}
      {offers && groupBy == GroupOffersBy.PARTNERS && groupByPartnersLayout(offers, state, setOfferToEdit)}
      {offers && groupBy == GroupOffersBy.COUNTRIES && groupByCountriesLayout(offers, state, setOfferToEdit)}
      {offers && offerToEdit && <OfferCrud offer={offerToEdit} offers={offers.plain} partners={offers.partners} onClose={() => setOfferToEdit(null)} />}
    </div>
  )
};

import React, { useEffect, useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Location,
  Navigate,
  Outlet,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { CustomProvider } from "rsuite";
import RequireAuth from "./ui/components/auth/require-auth";
import Auth from "./ui/components/auth/login";
import Layout from "./ui/components/auth/layout";
import AdminPage from "./ui/admin/admin-page";
import "rsuite/dist/rsuite.min.css";
import AppHub from "./ui/containers/app/app-hub";
import { NavbarTab } from "./ui/components/navbar/Navbar";
import ConstructorFlashApps from "./ui/components/constructor/constructor";
import { PanelUserAccessScope } from "@bprotsyk/aso-core";
import Offers from "./ui/containers/offers/offers";
import Domains from "./ui/containers/domains/domains";
import Apps from "ui/components/app/list/apps";
function App() {
  return (
    <CustomProvider theme="dark">
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={RouteAdapter as any}>
          <Routes>
            <Route path="/" element={<Navigate to="/domains" />} />

            <Route
              path="/auth"
              element={<AppHub component={<Auth />} tab={NavbarTab.AUTH} />}
            />

            <Route
              element={
                <RequireAuth allowedRoles={[PanelUserAccessScope.ADMIN]} />
              }
            >
              <Route
                path="/offers"
                element={
                  <AppHub component={<Offers />} tab={NavbarTab.OFFERS} />
                }
              />
            </Route>
            <Route
              element={
                <RequireAuth allowedRoles={[PanelUserAccessScope.ADMIN]} />
              }
            >
              <Route
                path="/apps"
                element={
                  <AppHub component={<Apps />} tab={NavbarTab.OFFERS} />
                }
              />
            </Route>
            <Route
              element={
                <RequireAuth allowedRoles={[PanelUserAccessScope.ADMIN]} />
              }
            >
              <Route
                path="/domains"
                element={
                  <AppHub component={<Domains />} tab={NavbarTab.DOMAINS} />
                }
              />
            </Route>
          </Routes>
        </QueryParamProvider>
      </BrowserRouter>
    </CustomProvider>
  );
}

const RouteAdapter = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: Location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

export default App;

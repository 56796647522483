import { GroupOffersBy, GroupOffersByQuery, ShowHiddenOffersQuery as ShowHiddenOffersQuery, IOffersAdminResponse, TOffer } from "types/offers-temporary";
import http from "./http";
import { IApp, IDomain, IDomainsBuyRequestResponse, INamecheapBuyResult } from "@bprotsyk/aso-core";
const DEPLOY_STATUS = "delpoy_status";

//TODO add  IDomainSetupResult (Cannot find name 'IDomainSetupResult')
interface IDomainSetupResult {
  succeed: string[],
  failed: string[]
}
// TODO add interface for IParams (aco-core)
interface IParamsAddDomain {
  domains: string[],
  ip: string,
}

interface IParamsBuyDomain {
  domains: string[];
  duration?: number;
  ip: string;
}

interface IParamsEditDomain {
  domains: DNSRecord[];
  duration?: number;
  ip: string;
}


type DNSRecords = TXTRecord | MXRecord;
// Інтерфейс для TXT-записів
interface TXTRecord extends DNSRecord {
  type: "TXT";
}

interface IStaticDeploy {
  file: any,
  name: string,
}

// Інтерфейс для MX-записів
interface MXRecord extends DNSRecord {
  type: "MX";
  priority: number;
}

interface DNSRecord {
  type: string;
  value: string;
}

interface IStaticDeploy {
  file: any,
  name: string,
}



const getAllOffers = async (groupBy: string, showHidden: boolean, showActive: boolean) => {
  const response = await http.get<IOffersAdminResponse>(`offers?${GroupOffersByQuery}=${groupBy}&${ShowHiddenOffersQuery}=${showHidden}`)

  return response.data
}

const getDomains = async () => {
  const response = await http.get<IDomain[]>(`/domains`);
  return response.data;
}

const getDomainBalance = async () => {
  const response = await http.get<number>(`/domains/balance`);
  return response.data;
}

const getApps = async (filter?: string, activeOnly: boolean = true) => {
  let path = `/apps`
  if (activeOnly) path += '?active=true'
  if (filter) filter += path.includes('?') ? '&' : '?' + 'filter = true'

  const response = await http.get<IApp[]>(path)
  return response.data;
}


const addDomain = async (params: IParamsAddDomain) => {
  try {
    const response = await http.post<IDomainSetupResult>(`/domains/add`, params);
    return response;
  } catch (error) {
    console.error(error)
  }
}

const getBuyDomain = async (params: IParamsBuyDomain) => {
  try {
    const response = await http.post<IDomainsBuyRequestResponse>(`/domains/create-buy-request`, params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

const buyDomains = async (params: string) => {
  try {
    const response = await http.post<INamecheapBuyResult>(`/domains/buy?requestId=${params}`);
    return response;
  } catch (error) {

  }
}

const editDomain = async (params: Partial<IDomain>) => {
  try {
    const response = await http.put<IDomain>(`/domains/edit`, params);
    return response;
  } catch (error) {
    console.error(error)
  }
}

const uploadStatic = async (body: IStaticDeploy) => {
  try {
    const file = body.file[0].blobFile;
    const formData = new FormData();
    formData.append('name', body.name);
    formData.append('file', file);
    const { blobFile } = body.file;
    console.log(blobFile);
    const response = await http.post(`/domains/upload-files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status == 200) {
      sessionStorage.setItem(DEPLOY_STATUS, "true");
    } else {
      sessionStorage.setItem(DEPLOY_STATUS, "false");
    }
    return response;
  } catch (error) {
    console.error(error)
    sessionStorage.setItem(DEPLOY_STATUS, "false");
  }
}

export const PanelService = { getAllOffers, getDomains, getDomainBalance, addDomain, getBuyDomain, buyDomains, editDomain, getApps, uploadStatic,  } 
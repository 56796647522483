// ListApps.tsx
import React, { useEffect, useState } from 'react';
import { Input, InputGroup, Table } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import './_apps.scss';
import { PanelService } from 'network/panel/network';
import { IApp } from '@bprotsyk/aso-core';

const { Column, HeaderCell, Cell } = Table;

const Apps: React.FC = () => {
  const [apps, setApps] = useState<IApp[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  // Fetch apps on component mount
  useEffect(() => {
    fetchApps();
  }, []);

  // Fetch apps from the server
  const fetchApps = async () => {
    setLoading(true);
    try {
      const data = await PanelService.getApps();
      setApps(data);
    } catch (error) {
      console.error('Error fetching apps:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle search input change
  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  // Filter apps based on search input
  const filteredApps = apps.filter((app) => {
    const searchLower = search.toLowerCase();
    const appIdString = `#${app.id}`;
    const integrationType = app.integrationVersion || '';

    return (
      appIdString.includes(searchLower) ||
      integrationType.toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="apps">
      <div className="header">
        <InputGroup inside style={{ width: 300 }}>
          <Input
            placeholder="Search by App ID or Integration Type"
            value={search}
            onChange={handleSearchChange}
          />
          <InputGroup.Addon>
            <SearchIcon />
          </InputGroup.Addon>
        </InputGroup>
      </div>

      <Table
        data={filteredApps}
        loading={loading}
        autoHeight
        bordered
        cellBordered
      >
        <Column flexGrow={1} align="left">
          <HeaderCell>App</HeaderCell>
          <Cell>
            {(rowData: IApp) => (
              <span>
                #{rowData.id}: {rowData.integrationVersion}
              </span>
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  );
};

export default Apps;

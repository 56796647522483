import {
  AutoComplete,
  Button,
  Dropdown,
  Form,
  Input,
  InputGroup,
  Loader,
  Modal,
  Stack,
} from "rsuite";
import "./_host-settings.scss";
import TrashIcon from "@rsuite/icons/Trash";
import { useEffect, useState } from "react";
import { DomainTarget, IDomain } from "@bprotsyk/aso-core";
import { v4 as uuidv4 } from "uuid";
import FileUploadIcon from "@rsuite/icons/FileUpload";

import { error } from "console";
import { set } from "react-hook-form";
import { PanelService } from "network/panel/network";

const recordsValues = ["TXT", "MX"];

interface IProps {
  editingDomainData: IDomain | null;
  onEditingModal: () => void;
  onStaticModal: () => void;
  load: boolean;
}

export default function EditingRecordsModal({
  editingDomainData,
  onEditingModal,
  onStaticModal,
  load,
}: IProps) {
  const [domainData, setDomainData] = useState<IDomain | null>(
    editingDomainData
  );
  const [Records, setRecords] = useState(new Map());
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [addTxtBtn, setAddTxtBtn] = useState<boolean>(false);
  const [addMxBtn, setAddMxBtn] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteKey, setDeleteKey] = useState<string>();
  const [ipErr, setIpErr] = useState(false);
  const [ip, setIp] = useState(editingDomainData?.ip || "");
  const [selectedOption, setSelectedOption] = useState("");

  const calculateRowsForText = (text: string) => {
    const normalizedText = text.replace(/\s+/g, " ").trim();
    if (!normalizedText) {
      return 1;
    }
    const charsPerLine = 30;
    const lines = normalizedText.split("\n").reduce((acc, line) => {
      return acc + Math.ceil(line.length / charsPerLine);
    }, 0);
    // Повертаємо кількість рядків або мінімум 1
    return Math.max(lines, 1);
  };

  const handleTextareaChange = (recordKey: string, event: any) => {
    const inputValue = event.target.value;

    setRecords((prevRecords) => {
      const currentRecord = prevRecords.get(recordKey);
      if (!currentRecord) return prevRecords;

      const updatedRecords = new Map(prevRecords);

      updatedRecords.set(recordKey, {
        ...currentRecord,
        value: inputValue, // Оновлюємо тільки значення
      });

      return updatedRecords;
    });
    checkForChanges();
  };

  const handleTextareaKeyDown = (recordKey: string, event: any) => {
    if (event.key === "Enter") {
      setRecords((prevRecords) => {
        const currentRecord = prevRecords.get(recordKey);
        if (!currentRecord) return prevRecords;

        const updatedRecords = new Map(prevRecords);

        updatedRecords.set(recordKey, {
          ...currentRecord,
          rows: currentRecord.rows + 1, // Збільшуємо тільки рядки
        });

        return updatedRecords;
      });
      checkForChanges();
    }
  };

  useEffect(() => {
    const newRecords = new Map();

    // Обробка txtRecords (масив)
    editingDomainData?.txtRecords?.forEach((record) => {
      const rows = calculateRowsForText(record);
      newRecords.set(uuidv4(), {
        rows,
        deleted: false,
        value: record,
        type: "TXT",
      });
    });

    // Обробка mxRecord (рядок)
    if (editingDomainData?.mxRecord) {
      const mxRecord = editingDomainData.mxRecord;
      const rows = calculateRowsForText(mxRecord);
      console.log(rows);
      newRecords.set(uuidv4(), {
        rows,
        deleted: false,
        value: mxRecord,
        type: "MX",
      });
    }

    // Перевірка, чи є хоча б один запис
    if (newRecords.size > 0) {
      setAddTxtBtn(true);
      setAddMxBtn(true);
    } else {
      setAddTxtBtn(false);
      setAddMxBtn(false);
    }

    setRecords(newRecords);
  }, [editingDomainData]);

  const ipPattern =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  useEffect(() => {
    setIsInitialized(true);
  }, [Records, ip, isInitialized]);

  const checkForChanges = () => {
    if (!isInitialized) {
      return; // Якщо компонент тільки ініціалізується, ми не перевіряємо зміни
    }

    const hasIpChanged = ip !== editingDomainData?.ip;
    const originalTxtRecordsSet = new Set(editingDomainData?.txtRecords || []);

    const hasTxtRecordsChanged = Array.from(Records.values()).some(
      (currentRecord) => {
        return (
          currentRecord.deleted !== false || // Якщо запис позначено як видалений
          !originalTxtRecordsSet.has(currentRecord.value) || // Якщо значення нове (відсутнє в оригінальних даних)
          (currentRecord.value &&
            !originalTxtRecordsSet.has(currentRecord.value)) // Якщо значення змінилося
        );
      }
    );

    setIsFormChanged(hasIpChanged || hasTxtRecordsChanged);
  };

  const handleIpChange = (ip: string) => {
    setIp(ip);
    checkForChanges();
  };
  useEffect(() => {
    if(isInitialized){
      if (!ipPattern.test(ip)) {
        setIpErr(!ipErr);
      } else {
        setIpErr(!ipErr);
      }
    }
  }, [ip]);

  useEffect(() => {
    if (isInitialized) {
      checkForChanges();
    }
  }, [ip, Records]);

  let ipElement = (
    <InputGroup size="lg">
      <AutoComplete
        value={ip}
        onChange={handleIpChange}
        data={[]}
        size="lg"
        style={{
          outline: ipErr ? `solid 1px red` : `none`,
        }}
      />
      {domainData?.target === DomainTarget.HOSTING && (
        <InputGroup.Button
          tabIndex={-1}
          appearance="primary"
          color="blue"
          onClick={onStaticModal}
          disabled={load}
        >
          {!load && <FileUploadIcon />}
          {load && <Loader />}
        </InputGroup.Button>
      )}
    </InputGroup>
  );

  const prohibitionToClose = () => {
    if (!isFormChanged) {
      onEditingModal();
    }
  };

  const handleSave = () => {
    if (isFormChanged) {
      const txtRecords = Array.from(Records.entries())
        .filter(([_, value]) => !value.deleted && value.type === "TXT")
        .map(([_, value]) => value.value);

      const mxRecords = Array.from(Records.entries())
        .filter(([_, value]) => !value.deleted && value.type === "MX")
        .map(([_, value]) => value.value);

      let recordsBody: Partial<IDomain> = {
        ...domainData,
        ip: ip,
        txtRecords: txtRecords,
        mxRecord: mxRecords[0],
      };

      // Перевірка наявності значення для MX-запису
      if (mxRecords[0] !== undefined) {
        recordsBody.mxRecord = mxRecords[0];
      }
      console.log("Prepared records body:", recordsBody);

      PanelService.editDomain(recordsBody)
        .then((res: any) => {
          console.log("Server response:", res);
        })
        .catch((error: any) => {
          console.log("Error during saving:", error);
        });
      setIsFormChanged(false);
    }
  };

  const handleAbolition = () => {
    setIsFormChanged(!isFormChanged);
  };

  const handleAddTxtRecord = (value: string) => {
    const newRecord = { rows: 1, deleted: false, type: value };
    Records.set(uuidv4(), newRecord);
    setRecords(new Map(Records));
    setIsFormChanged(true);
    setSelectedOption(value);
  };

  const handleDelete = () => {
    setRecords((prevRecords) => {
      const updatedRecords = new Map(prevRecords);
      updatedRecords.set(deleteKey, {
        ...updatedRecords.get(deleteKey),
        deleted: true,
      });
      return updatedRecords;
    });
    setDeleteModal(!deleteModal);
    setIsFormChanged(true);
  };

  const confimDelete = (recordKey: string) => {
    setDeleteKey(recordKey);
    setDeleteModal(!deleteModal);
  };
  console.log(Records);

  return (
    <div className="editing_records_modal">
      <Modal
        backdrop="static"
        role="alertdialog"
        open={deleteModal}
        // onClose={handleClose}
        size="xs"
      >
        <Modal.Body>
          {/* <RemindIcon style={{ color: "#ffb300", fontSize: 24 }} /> */}
          <p className="confim_text_delete">
            Після підтвердження запис буде остаточно видалений. Цю дію не можна
            скасувати. Ви впевнені, що хочете продовжити?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary" color="red">
            Підтвердити
          </Button>
          <Button onClick={() => setDeleteModal(false)} appearance="subtle">
            Відміна
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop={"static"}
        size="sm"
        open={true}
        onClose={() => {
          if (!isFormChanged) {
            onEditingModal();
          }
        }}
      >
        <Modal.Header>
          <Modal.Title>
            {`Налаштування :`}
            <p className="title">{domainData?.name}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Stack
              className="content"
              spacing={7}
              direction="column"
              alignItems="stretch"
            >
              <Form.ControlLabel>IP</Form.ControlLabel>
              {ipElement}
              {Array.from(Records.entries()).map(
                ([recordKey, recordValue], index) =>
                  !recordValue.deleted && (
                    <div key={recordKey} className="txt_flex_container">
                      <div className="input_label_wrap">
                        <span className="txt_label">
                          {recordValue.type === "TXT"
                            ? `txt[${index}]`
                            : `mx[${index}]`}
                        </span>
                        <div className="laber_delete_wrap">
                          {recordValue.type === "TXT" ? (
                            <textarea
                              rows={recordValue.rows}
                              placeholder="TXT Record"
                              onChange={(e) =>
                                handleTextareaChange(recordKey, e)
                              }
                              onKeyDown={(e) =>
                                handleTextareaKeyDown(recordKey, e)
                              }
                              className="ip_input text_area"
                              style={{
                                borderColor: recordValue.deleted
                                  ? "red"
                                  : "#3c3f43",
                              }}
                              defaultValue={recordValue.value}
                            />
                          ) : (
                            <textarea
                              placeholder="MX Record"
                              rows={recordValue.rows}
                              onChange={(e) =>
                                handleTextareaChange(recordKey, e)
                              }
                              onKeyDown={(e) =>
                                handleTextareaKeyDown(recordKey, e)
                              }
                              className="ip_input text_area"
                              style={{
                                borderColor: recordValue.deleted
                                  ? "red"
                                  : "#3c3f43",
                              }}
                              defaultValue={recordValue.value}
                            />
                          )}
                          <Button
                            appearance="primary"
                            className="delete_btn"
                            onClick={() => confimDelete(recordKey)}
                          >
                            <TrashIcon className="trash_icon" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </Stack>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="add_btn_container">
            <Dropdown title={"Додати TXT/MX запис"}>
              {recordsValues.map((option: string) => (
                <Dropdown.Item
                  key={option}
                  onSelect={() => handleAddTxtRecord(option)}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
          <Button
            appearance="subtle"
            onClick={handleAbolition}
            disabled={!isFormChanged}
          >
            Відміна
          </Button>
          <Button
            appearance="primary"
            disabled={!isFormChanged}
            onClick={handleSave}
            className="save_button"
          >
            {"Зберегти"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import { DomainStatus, DomainTarget } from "@bprotsyk/aso-core";
import React, { useEffect, useState } from "react";
import { Dropdown, Checkbox } from "rsuite";

interface Option {
  label: string;
  value: string;
}

interface FilterState {
  [key: string]: string[];
}

interface FilterState {
  status: string[];
  archived: string[];
  target: string[];
}

interface IProps {
  onSetFilter: (e: FilterState) => void;
}
const PREF_FILTER_SHOW_ARCHIVED = "filter-selector";

const MyCustomSelector: React.FC<IProps> = ({ onSetFilter }) => {
  const [selectedItems, setSelectedItems] = useState<FilterState>(() => {
    const savedFilters = localStorage.getItem(PREF_FILTER_SHOW_ARCHIVED);
    return savedFilters
      ? JSON.parse(savedFilters)
      : { status: [], archived: [], target: [] };
  });

  const handleCheck = (key: string, value: string) => {
    setSelectedItems((prevState) => {
      const nextItems = [...prevState[key]];
      const valueIndex = nextItems.indexOf(value);

      if (valueIndex === -1) {
        nextItems.push(value);
      } else {
        nextItems.splice(valueIndex, 1);
      }

      const newFilterState = {
        ...prevState,
        [key]: nextItems,
      };

      localStorage.setItem(
        PREF_FILTER_SHOW_ARCHIVED,
        JSON.stringify(newFilterState)
      );

      return newFilterState;
    });
  };

  useEffect(() => {
    onSetFilter(selectedItems);
  }, [selectedItems]);

  const renderDropdownItem = (label: string, key: string, values: Option[]) => (
    <Dropdown.Item panel key={key} className="custom_piker_name">
      <div className="label_name">{label}</div>
      {values.map((item) => (
        <Checkbox
          key={item.value}
          value={item.value}
          checked={selectedItems[key].includes(item.value)}
          onChange={() => handleCheck(key, item.value)}
        >
          {item.label}
        </Checkbox>
      ))}
    </Dropdown.Item>
  );

  return (
    <Dropdown title="Фільтр" activeKey={null}>
      {renderDropdownItem("Статус", "status", [
        { label: "Активний", value: DomainStatus.READY_AND_NOTIFIED },

        { label: "Неактивний", value: DomainStatus.WITHOUT_CLOUDFLARE },
        { label: "В очікуванні", value: DomainStatus.PENDING },
      ])}
      {renderDropdownItem("Архівовані", "archived", [
        { label: "Архівовані", value: "archived" },
        { label: "Неархівовані", value: "notArchived" },
      ])}
      {renderDropdownItem("Таргету", "target", [
        { label: "Хостинг", value: DomainTarget.HOSTING },
        { label: "Кейтаро", value: DomainTarget.KEITARO },
        { label: "Мейн", value: DomainTarget.MAIN },
        { label: "Інше", value: DomainTarget.OTHER },
        { label: "Невідомо", value: DomainTarget.UNKNOWN },
      ])}
    </Dropdown>
  );
};

export default MyCustomSelector;
